<template>
  <v-data-table
    :items="items"
    hide-default-footer
    :headers="itemHeaders"
    dense
    class="elevation-0"
  >
    <template v-slot:item.id="{ item, index}">
      {{ order.num }}-{{ index + 1 }}
    </template>
    <template v-slot:item.estimatedDeliveryDate="{ item }">
      {{ item.po.end | dateFormat(true) }}
    </template>
    <template v-slot:item.checked="{ item, index }">
      <template
        v-if="item.po"
      >
        <v-menu
          bottom
          right
          transition="scale-transition"
          origin="top left"
          v-if="item.status === '02'"
        >
          <template v-slot:activator="{ on }">
            <v-chip
              class="ma-2"
              close
              :color="statusObj[item.status].color"
              close-icon="mdi-chevron-down"
              small
              v-on="on"
            >
              <v-avatar left>
                <v-icon>mdi-checkbox-marked-circle</v-icon>
              </v-avatar>
              {{ statusObj[item.status].text }}
            </v-chip>
          </template>
          <v-card>
            <v-list dense>
              <v-list-item @click="checkItem(index)">
                <v-list-item-action>
                  <v-icon color="success">mdi-check-circle</v-icon>
                </v-list-item-action>
                <v-list-item-title>Aprobar</v-list-item-title>
              </v-list-item>
              <v-list-item @click="() => {}">
                <v-list-item-action>
                  <v-icon color="warning">mdi-alert-circle</v-icon>
                </v-list-item-action>
                <v-list-item-title>Se requiere corregir</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-card>
        </v-menu>
        <v-chip
          class="ma-2"
          :color="statusObj[item.status].color"
          small
          v-else
        >
          <v-avatar left>
            <v-icon>mdi-checkbox-marked-circle</v-icon>
          </v-avatar>
          {{ statusObj[item.status].text }}
        </v-chip>
      </template>
    </template>
    <template v-slot:item.checkedAt="{ item }">
      {{ item.checkedAt | dateFormat(true) }}
    </template>
    <template v-slot:item.actions="{ item }">
      <div style="display: flex;  flex-direction: row; align-items: center; gap: .5rem;">
        <dialog-comments :item="item.po" icon="comments"></dialog-comments>
        <p-o-dialog :item="item.po" icon="display" v-if="false"></p-o-dialog>
      </div>
    </template>
  </v-data-table>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import tools from '@/api/tools'
import PODialog from '@/components/PODialog.vue'
import DialogComments from '@/components/DialogComments.vue'
import redaApp from '../api/redaApp'

export default {
  name: 'AOTableExpanded',
  props: ['items', 'order'],
  components: {
    PODialog,
    DialogComments
  },
  data: () => ({
    search: null,
    page: 1,
    expanded: [],
    itemsPerPage: 10,
    statusItems: [
      { text: 'Atrasada', value: '01' },
      { text: 'En peligro', value: '02' },
      { text: 'Pendiente', value: '03' },
      { text: 'Iniciada', value: '04' },
      { text: 'Lista', value: '05' },
      { text: 'Entregada', value: '06' },
      { text: 'Todas', value: null }
    ],
    status: null,
    headersExcel: [
      { text: 'Nombre', value: 'client.name' },
      { text: 'Contacto', value: 'client.phone' },
      { text: 'Cantón', value: 'address.conty.text' },
      { text: 'Distrito', value: 'address.district.text' },
      { text: 'Dirección', value: 'address.others' },
      { text: 'Masas', value: 'mmn01.quantity' },
      { text: 'Chorreadas', value: 'cpn01.quantity' },
      { text: 'Observaciones', value: 'observations' }
    ],
    priorityObj: {
      1: { text: 'Alta', color: 'error' },
      2: { text: 'Media', color: 'warning' },
      3: { text: 'Baja', color: 'success' }
    },
    statusObj: {
      '01': { text: 'Pendiente de corrección', color: 'warning' },
      '02': { text: 'Lista para revición', color: 'primary' },
      '03': { text: 'En preparación', color: 'info' },
      '04': { text: 'Aprobado', color: 'success' }
    }
  }),
  methods: {
    downloadExcel () {
      tools.table2excel(this.getOrders, this.headersExcel, 'Ordenes de trabajo')
    },
    setExapanded (item) {
      this.expanded = [item]
    },
    checkItem (i) {
      const items = this.items.map((item) => ({
        ...item,
        product: item.product?.commercialCode || null,
        po: item.po?.uid || null
      }))
      items[i].check = true
      items[i].checkedAt = new Date().getTime()
      redaApp.update('orders', { items }, this.order.uid)
        .catch((e) => {
          this.showError('Ocurrio un error al anular pago')
          console.error(e)
        })
    },
    ...mapActions('message', ['showSuccess', 'showError'])
  },
  computed: {
    getOrders () {
      return this.orders.filter(({ task }) => (task?.status === this.status || !this.status))
    },
    itemHeaders () {
      return [
        { text: 'ID', value: 'id' },
        { text: 'Decripción', value: 'product.description' },
        { text: 'Fecha de entrega', value: 'estimatedDeliveryDate' },
        { text: 'Estado', value: 'checked' },
        { text: 'Aprovado en', value: 'checkedAt' },
        { text: 'Acciones', value: 'actions' }
      ]
    },
    headers () {
      return [
        { text: 'ID', value: 'num' },
        { text: '', value: 'data-table-expand' },
        { text: 'Cliente', value: 'customer' },
        { text: 'Fecha de entrega', value: 'created' },
        { text: 'Estado', value: 'status' },
        { text: 'Facturación', value: 'billing' },
        { text: 'Entrega', value: 'delivery' }
      ]
    },
    ...mapGetters('user', [])
  }
}
</script>
