var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex mt-5 mb-0"},[_c('div',{staticClass:"px-2"},[_c('v-text-field',{attrs:{"label":"Buscar","append-icon":"mdi-magnify","dense":"","solo":"","flat":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('div',{staticClass:"px-2"},[_c('v-select',{attrs:{"items":_vm.statusItems,"label":"Estado","dense":"","solo":"","flat":""},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}})],1),(false)?_c('div',{staticClass:"px-2  mr-auto"},[_c('v-select',{attrs:{"label":"Solo field","dense":"","solo":"","flat":""}})],1):_vm._e(),(false)?_c('div',{staticClass:"px-2"},[_c('v-select',{attrs:{"label":"Solo field","dense":"","solo":"","flat":""}})],1):_vm._e()]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.getOrders,"search":_vm.search,"loading":_vm.loading,"items-per-page":_vm.itemsPerPage,"page":_vm.page,"sort-by":"num","sort-desc":"","hide-default-footer":"","single-expand":true,"item-key":"uid","show-expand":"","expanded":_vm.expanded},on:{"update:page":function($event){_vm.page=$event},"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.products",fn:function(ref){
var item = ref.item;
return _vm._l((item.items),function(product,i){return _c('div',{key:i},[_vm._v(_vm._s(product.product.description)+": "+_vm._s(product.quantity)+" "+_vm._s(product.product.unit))])})}},{key:"item.created",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateFormat")(item.created,true))+" ")]}},{key:"item.deadline",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateFormat")(item.deadline))+" ")]}},{key:"item.num",fn:function(ref){
var item = ref.item;
return [_vm._v(" #"+_vm._s(item.num)+" ")]}},{key:"item.customer",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("capitalizeWords")((item.customer ? item.customer.name : null)))+" ")]}},{key:"item.completed",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("percentage")((item.task && item.task instanceof Object ? item.task.completed : 0)))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('div',{class:("ma-1 " + (item.checked ? 'success' : 'error') + "--text")},[_vm._v(" "+_vm._s(item.checked ? 'Aprobada' : 'Pendiente')+" ")])]}},{key:"item.priority",fn:function(ref){
var item = ref.item;
return [(item.priority)?_c('v-chip',{staticClass:"ma-1",attrs:{"color":_vm.priorityObj[item.priority].color}},[_vm._v(" "+_vm._s(_vm.priorityObj[item.priority].text)+" ")]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [((item.task && item.task instanceof Object))?_c('OrderDialog',{attrs:{"item":item,"icon":false}}):_vm._e()]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"ma-0 pa-0",attrs:{"colspan":headers.length}},[_c('a-o-table-expanded',{attrs:{"items":item.items,"order":item}})],1)]}},{key:"item.data-table-expand",fn:function(ref){
var item = ref.item;
var isExpanded = ref.isExpanded;
return [_c('div',{staticClass:"d-flex flex-row"},[_c('div',[_vm._v("#"+_vm._s(item.num))]),_c('v-icon',{on:{"click":function($event){return _vm.setExapanded(isExpanded, item)}}},[_vm._v(_vm._s(isExpanded ? 'mdi-chevron-up' : 'mdi-chevron-down'))])],1)]}},{key:"item.delivery",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-row"},[_c('div',[_vm._v(_vm._s(_vm.deliveryMethods[item.delivery ? item.delivery.method : '001'].name))]),_c('v-icon',{attrs:{"x-small":"","color":"info"},on:{"click":function () {}}},[_vm._v("mdi-information-outline")])],1)]}},{key:"item.billing",fn:function(ref){
var item = ref.item;
return [(!item.billing)?_c('dialog-issue-voucher',{attrs:{"order":item,"label":"Por facturar"}}):_vm._e(),(false)?_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.billing(item)}}},[_vm._v(" "+_vm._s(item.billing ? item.collected ? 'Cobrada' : 'Por cobrar' : 'Por facturar')+" "),(item.billing)?_c('v-icon',{attrs:{"x-small":"","color":"info"},on:{"click":function () {}}},[_vm._v("mdi-information-outline")]):_vm._e()],1):_vm._e()]}},{key:"footer",fn:function(ref){
var props = ref.props;
return [_c('div',{staticClass:"d-flex justify-space-between pa-6 caption"},[_c('div',{staticClass:"caption"},[_vm._v("Mostrando "),_c('v-select',{staticClass:"caption",staticStyle:{"display":"inline-block","width":"68px"},attrs:{"outlined":"","dense":"","append-icon":"mdi-chevron-down","items":[
              { text: '5', value: 5 },
              { text: '10', value: 10 },
              { text: '15', value: 15 },
              { text: 'Todas', value: props.pagination.itemsLength } ]},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
            var item = ref.item;
return [_vm._v(_vm._s(item.value))]}}],null,true),model:{value:(_vm.itemsPerPage),callback:function ($$v) {_vm.itemsPerPage=$$v},expression:"itemsPerPage"}}),_vm._v(" de "+_vm._s(props.pagination.itemsLength)+" ")],1),_c('v-pagination',{attrs:{"length":props.pagination.pageCount},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }