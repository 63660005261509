<template>
  <v-card>

    <v-card-title>D-104 - Declaración Jurada del Impuesto al Valor Agregado - IVA</v-card-title>

    <v-col cols="12" sm="4">
      <v-select
      :items="getCompany.activities"
      item-value="code"
      item-text="description"
      label="Actividad económica"
      v-model="activityCode"
      v-show="getCompany.activities.length > 1"
      ></v-select>
    </v-col>

    <template
        v-for="(item, i) in getTaxReturnsD104"
    >

      <v-list-item :key="item.id">
        <v-list-item-content>
          <v-list-item-title>{{ item.text }}</v-list-item-title>
        </v-list-item-content>
        <v-list-item-action>{{ item.value | numberFormat }}</v-list-item-action>
      </v-list-item>

      <template
        v-for="item2 in item.items"
      >
        <v-card-text :key="item2.id">

          <v-list-item  :key="i">
            <v-list-item-content>
              {{ item2.text }}
            </v-list-item-content>
            <v-list-item-action>{{ item2.value | numberFormat }}</v-list-item-action>
          </v-list-item>

          <v-simple-table
            dense
            :key="item2.id"
            v-if="!item2.table"
          >
            <template v-slot:default>
              <tbody>
                <tr
                  v-for="item3 in item2.items"
                  :key="item3.id"
                >
                  <td>
                    {{ item3.text }}
                  </td>
                  <td class="text-right">
                    <input style="text-align: right;max-width: 90px;" :value="item3.value | numberFormat" readonly>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          x-small
                          v-bind="attrs"
                          v-on="on"
                          @click="copy(item3.value | numberFormat, item3.text)"
                      >
                        <v-icon>mdi-content-copy</v-icon>
                      </v-btn>
                      </template>
                      <span>Copiar</span>
                    </v-tooltip>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-data-table
            :headers="[
              {text: 'Servicios', value: 'detail'},
              {text: 'Afectas 1%', value: '1'},
              {text: 'Afectas 2%', value: '2'},
              {text: 'Afectas 4%', value: '4'},
              {text: 'Afectas 8%', value: '8'},
              {text: 'Afectas 13%', value: '13'}
            ]"
            :items="item2.items"
            dense
            hide-default-footer
            :items-per-page="item2.items.length"
            v-else
          >
            <template v-slot:item.1="{ item }">
              <input style="text-align: right;max-width: 90px;" :value="item[1] | numberFormat" readonly>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    x-small
                    v-bind="attrs"
                    v-on="on"
                    @click="copy(item[1], item.detail)"
                >
                  <v-icon>mdi-content-copy</v-icon>
                </v-btn>
                </template>
                <span>Copiar</span>
              </v-tooltip>
            </template>
            <template v-slot:item.2="{ item }">
              <input style="text-align: right;max-width: 90px;" :value="item[2] | numberFormat" readonly>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    x-small
                    v-bind="attrs"
                    v-on="on"
                    @click="copy(item[2], item.detail)"
                >
                  <v-icon>mdi-content-copy</v-icon>
                </v-btn>
                </template>
                <span>Copiar</span>
              </v-tooltip>
            </template>
            <template v-slot:item.4="{ item }">
              <input style="text-align: right;max-width: 90px;" :value="item[4] | numberFormat" readonly>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    x-small
                    v-bind="attrs"
                    v-on="on"
                    @click="copy(item[4], item.detail)"
                >
                  <v-icon>mdi-content-copy</v-icon>
                </v-btn>
                </template>
                <span>Copiar</span>
              </v-tooltip>
            </template>
            <template v-slot:item.8="{ item }">
              <input style="text-align: right;max-width: 90px;" :value="item[8]" readonly>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    x-small
                    v-bind="attrs"
                    v-on="on"
                    @click="copy(item[8], item.detail)"
                >
                  <v-icon>mdi-content-copy</v-icon>
                </v-btn>
                </template>
                <span>Copiar</span>
              </v-tooltip>
            </template>
            <template v-slot:item.13="{ item }">
              <input style="text-align: right;max-width: 90px;" :value="item[13] | numberFormat" readonly>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    x-small
                    v-bind="attrs"
                    v-on="on"
                    @click="copy(item[13], item.detail)"
                >
                  <v-icon>mdi-content-copy</v-icon>
                </v-btn>
                </template>
                <span>Copiar</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card-text>
      </template>
    </template>

    <v-snackbar
      v-model="snackbar"
    >
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
import firebaseApi from '../api/firebaseApi'
import * as cabysD104 from '../assets/cabysD104.json'
import {
  mapState, mapGetters, mapMutations, mapActions
} from 'vuex'

const services = [
  'Al',
  'Alc',
  'Cm',
  'I',
  'Os',
  'Sp',
  'Spe',
  'St'
]

export default {
  name: 'D104',
  components: {},
  props: [],
  beforeCreate: function () {},
  created: function () {},
  beforeMount: function () {},
  mounted: function () {
    this.activityCode = this.getCompany.mainActivity
    // redaApp.importAttachmentsVouchers().then(() => console.log('Importing from email')).catch((e) => console.error(e))
    this.reset()
  },
  beforeUpdate: function () {},
  updated: function () {},
  beforeDestroy: function () {
    this.listenerVoucherReceivedItems()
    this.listenerVoucherSalesItems()
  },
  destroyed: function () {},
  data: () => ({
    snackbar: false,
    snackbarText: null,
    activityCode: null,
    voucherSalesItems: [],
    voucherReceivedItems: [],
    listenerVoucherSalesItems: null,
    listenerVoucherReceivedItems: null
  }),
  watch: {
    from (newValue) {
      this.reset()
    },
    to (newValue) {
      this.reset()
    }
  },
  methods: {
    copy (value, text) {
      this.snackbarText = 'Valor de "' + text + '" copiado al portapapeles'
      navigator.clipboard.writeText(value)
        .then(() => {
          setTimeout(() => {
            this.snackbar = false
          }, 3000)
          this.snackbar = true
        })
    },
    reset () {
      if (this.listenerVoucherReceivedItems) this.listenerVoucherReceivedItems()
      if (this.listenerVoucherSalesItems) this.listenerVoucherSalesItems()
      const to = this.to
      const from = this.from || 0
      // redaApp.importAttachmentsVouchers().then(() => console.log('Importing from email')).catch((e) => console.error(e))
      this.listenerVoucherReceivedItems = firebaseApi.db.collection('voucherItems')
        .where('receiver', '==', this.getCompany.identification)
        .where('date', '>', from)
        .where('date', '<', to)
        .onSnapshot(({ docs }) => {
          for (let i = 0; i < docs.length; i++) {
            const doc = docs[i]
            this.voucherReceivedItems.push({ ...doc.data(), uid: doc.id })
          }
        })
      this.listenerVoucherSalesItems = firebaseApi.db.collection('voucherItems')
        .where('issuer', '==', this.getCompany.identification)
        .where('date', '>', from)
        .where('date', '<', to)
        .onSnapshot(({ docs }) => {
          for (let i = 0; i < docs.length; i++) {
            const doc = docs[i]
            const types = ['01', '02', '03', '04', '09']
            const item = doc.data()
            if (types.includes(item.type) && item.treasuryMessage !== 'rechazado') this.voucherSalesItems.push({ ...item, uid: doc.id })
          }
        })
    },
    ...mapMutations([]),
    ...mapActions([])
  },
  computed: {
    getVoucherSalesItems () {
      return this.voucherSalesItems
    },
    getVoucherReceivedItems () {
      return this.voucherReceivedItems
    },
    getTaxReturnsD104 () {
      const salesItemsArray = this.getVoucherSalesItems.filter(({ activityCode }) => activityCode === this.activityCode)
      const sales = salesItemsArray.reduce((acc, curr) => {
        if (curr.taxes) {
          for (let index = 0; index < curr.taxes.length; index++) {
            const tax = curr.taxes[index]
            if (tax.code === '01') {
              if (!acc[tax.rate]) acc[tax.rate] = []
              acc[tax.rate].push(curr)
            }
          }
        } else {
          if (!acc['0']) acc['0'] = []
          acc['0'].push(curr)
        }
        return acc
      }, {})
      const periodSales = [
        { id: 'CASILLA_990300_MON', text: 'Bienes y servicios afectos al 1%', value: 0, items: [], iva: '1' },
        { id: 'CASILLA_990400_MON', text: 'Bienes y servicios afectos al 2%', value: 0, items: [], iva: '2' },
        { id: 'CASILLA_990500_MON', text: 'Bienes y servicios afectos al 4%', value: 0, items: [], iva: '4' },
        { id: 'CASILLA_990600_MON', text: 'Bienes y servicios afectos al 8%', value: 0, items: [], iva: '8' },
        { id: 'CASILLA_990700_MON', text: 'Bienes y servicios afectos al 13%', value: 0, items: [], iva: '13' },
        { id: 'CASILLA_990800_MON', text: 'Total otros rubros a incluir en la base imponible', value: 0, items: [] },
        { id: 'CASILLA_990900_MON', text: 'Ventas exentas (Art.8)', value: 0, items: [], iva: '0' }
      ]
      for (const [key, value] of Object.entries(cabysD104.sales)) {
        const items = sales[key]
        if (items) {
          for (let index = 0; index < value.length; index++) {
            const { cabys, id, text } = value[index]
            // eslint-disable-next-line camelcase
            const elementValue = items.reduce((acc, { code, taxable_base }) => {
              if (cabys.includes(code)) acc += Number(taxable_base)
              return acc
            }, 0)
            const i = periodSales.findIndex(({ iva }) => iva === key)
            periodSales[i].value += elementValue
            periodSales[i].items.push({ id, text, value: elementValue })
          }
        }
      }
      const periodPurchases = this.getVoucherReceivedItems
        .reduce((acc, curr) => {
          if (curr.taxes) {
            for (let index = 0; index < curr.taxes.length; index++) {
              const tax = curr.taxes[index]
              if (tax.code === '01') {
                if (Number(tax.rate) === 0) continue
                const detailFind = services.includes(curr.unit) ? 'Servicios' : 'Bienes'
                const j = acc.findIndex(({ id }) => id === 'CASILLA_995300_MON')
                const i = acc[j].items.findIndex(({ detail }) => detail === detailFind)
                acc[j].items[i][Number(tax.rate)] += Number(curr.taxable_base)
                acc[j].value += Number(curr.taxable_base)
              }
            }
          } else {
            const j = acc.findIndex(({ id }) => id === 'CASILLA_995500_MON')
            const i = acc[j].items.findIndex(({ id }) => id === 'CASILLA_02611_RDB_2_1')
            acc[j].items[i].value += Number(curr.taxable_base)
            acc[j].value += Number(curr.taxable_base)
          }
          return acc
        }, [
          {
            id: 'CASILLA_995300_MON',
            text: 'Compras de bienes y servicios locales',
            items: [
              { detail: 'Bienes', 1: 0, 2: 0, 4: 0, 8: 0, 13: 0 },
              { detail: 'Servicios', 1: 0, 2: 0, 4: 0, 8: 0, 13: 0 }
            ],
            table: true,
            value: 0
          },
          {
            id: 'CASILLA_995400_MON',
            text: 'Importación de bienes y adquisición de servicios del exterior',
            items: [
              { detail: 'Bienes', 1: 0, 2: 0, 4: 0, 8: 0, 13: 0 },
              { detail: 'Servicios', 1: 0, 2: 0, 4: 0, 8: 0, 13: 0 }
            ],
            table: true,
            value: 0
          },
          {
            id: 'CASILLA_995500_MON',
            text: 'Compras sin IVA soportado y/o con IVA soportado no acreditable',
            items: [
              { id: 'CASILLA_02611_RDB_2_1', text: 'Bienes y servicios exentos comprados localmente', value: 0 },
              { id: 'CASILLA_02611_RDB_2_2', text: 'Bienes y servicios exentos importados', value: 0 },
              { id: 'CASILLA_02612_RDB_2_1', text: 'Bienes y servicios no sujetos comprados localmente', value: 0 },
              { id: 'CASILLA_02612_RDB_2_2', text: 'Bienes y servicios no sujetos importados', value: 0 },
              { id: 'CASILLA_02613_RDB_2_1', text: 'Bienes y servicios con o sin IVA soportado no acreditable (incluye compras de otros regímenes o no relacionados con la actividad) comprados localmente', value: 0 },
              { id: 'CASILLA_02613_RDB_2_2', text: 'Bienes y servicios con o sin IVA soportado no acreditable (incluye compras de otros regímenes o no relacionados con la actividad) importados', value: 0 },
              { id: 'CASILLA_027900_MON', text: 'Bienes y servicios del artículo 19 de la LIVA', value: 0 },
              { id: 'CASILLA_02614_RDB_2_1', text: 'Autorizadas por la Dirección General de Hacienda', value: 0 },
              { id: 'CASILLA_02614_RDB_2_2', text: 'Autorizadas por la Dirección General de Tributación', value: 0 }
            ],
            value: 0
          }
        ])
      return [
        {
          id: 'CASILLA_990100_MON',
          text: 'Ventas sujetas (Base Imponible)',
          value: periodSales.reduce((acc, { value }) => (acc += value), 0),
          items: periodSales
        },
        {
          id: 'CASILLA_995100_MON',
          text: 'Compras del periodo',
          value: periodPurchases.reduce((acc, { value }) => (acc += value), 0),
          items: periodPurchases
        }
      ]
    },
    ...mapState(['to', 'from']),
    ...mapGetters('company', [
      'getCompany'
    ])
  }
}
</script>
