var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"sort-by":"receiver","search":_vm.search,"dense":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-toolbar-title',[_vm._v("Total ventas por cliente")]),_c('v-spacer'),_c('v-text-field',{staticClass:"mt-3 py-2 mr-2",attrs:{"label":"Buscar","append-icon":"mdi-magnify"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-btn',{on:{"click":_vm.downloadExcel}},[_c('v-icon',[_vm._v("mdi-file-excel")])],1)],1)]},proxy:true},{key:"item.customerName",fn:function(ref){
var item = ref.item;
return [(item.customerId !== '000000000')?_c('RouterLink',{attrs:{"to":'/customer/'+item.customerId}},[_vm._v(_vm._s(item.customerName))]):_c('div',[_vm._v(_vm._s(item.customerName))])]}},{key:"item.cabys",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.code && _vm.cabys[item.code].description)+" ")]}},{key:"item.subtotal",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currencyFormat")(item.subtotal))+" ")]}},{key:"item.tax_net",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currencyFormat")(item.tax_net))+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-row"},[_c('v-btn',{staticClass:"d-flex justify-center",attrs:{"icon":"","to":'/voucher/'+item.key}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-information ")])],1),_c('v-btn',{staticClass:"d-flex justify-center",attrs:{"icon":""}},[_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.newReceipt(item)}}},[_vm._v(" mdi-cash-plus ")])],1)],1)]}},{key:"body.append",fn:function(){return [_c('tr',[_c('td',{staticClass:"title",attrs:{"colspan":"2"}},[_vm._v(" Total ventas ")]),_c('td'),_c('td'),_c('td',{staticClass:"title",staticStyle:{"text-align":"end"}},[_vm._v(" "+_vm._s(_vm._f("currencyFormat")(_vm.totalSales))+" ")])])]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }