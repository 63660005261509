import tools from '@/api/tools.js'
import moment from 'moment'
import printer from '@/api/printer.js'
import redaApp from '../../api/redaApp'
const invoice = {
  namespaced: true,
  state: () => ({
    voucherType: null,
    headquarters: null,
    terminal: null,
    documentSituation: '1',
    activityCode: null,
    sequence: 1,
    issuer: null,
    receiver: null,
    receiverId: null,
    condition: '01',
    creditTerm: null,
    paymentType: '01',
    items: [],
    summary: {},
    currency: 'CRC',
    exchangeRate: 1,
    order: null,
    otherCharges: [],
    others: null,
    seller: null,
    references: null,
    proforma: null,
    delivery: null,
    applicant: null
  }),
  mutations: {
    setOrder (state, order) {
      state.order = order
    },
    setReferences (state, references) {
      state.references = references
    },
    setSeller (state, seller) {
      state.seller = seller
    },
    setVoucherType (state, voucherType) {
      state.voucherType = voucherType
    },
    setCondition (state, condition) {
      state.condition = condition
    },
    setCreditTerm (state, creditTerm) {
      state.creditTerm = creditTerm
    },
    setCurrency (state, currency) {
      state.currency = currency
    },
    setExchangeRate (state, exchangeRate) {
      state.exchangeRate = exchangeRate
    },
    newItem (state, item) {
      state.items.push(item)
    },
    updateItem (state, payload) {
      state.items.splice(payload.index, 1, payload.item)
    },
    updateHsCode (state, payload) {
      state.items[payload.index].hsCode = payload.hsCode
    },
    updateQuantity (state, payload) {
      state.items[payload.index].quantity = payload.quantity
    },
    updateDiscount (state, payload) {
      state.items[payload.index].discountRate = payload.discountRate
    },
    deleteItem (state, index) {
      state.items.splice(index, 1)
    },
    deleteReceiver (state) {
      state.receiver = null
      state.receiverId = null
    },
    cleanInvoice (state, payload) {
      // state.voucherType = payload
      state.headquarters = null
      state.terminal = null
      state.documentSituation = '1'
      state.activityCode = null
      state.sequence = 1
      state.issuer = null
      state.receiver = null
      state.receiverId = null
      state.condition = '01'
      state.creditTerm = null
      state.paymentType = '01'
      state.items = []
      state.summary = {}
      state.currency = 'CRC'
      state.exchangeRate = 1
      state.order = null
      state.otherCharges = []
      state.others = null
      state.receiptId = null
      state.proforma = null
      state.delivery = null
      state.applicant = null
    },
    setReceiver (state, receiver) {
      state.receiver = receiver
    },
    setApplicant (state, applicant) {
      state.applicant = applicant
    },
    setIssuer (state, issuer) {
      state.issuer = issuer
    },
    setSummary (state, summary) {
      state.summary = summary
    },
    setActivity (state, code) {
      state.activityCode = code
    },
    setSequence (state, sequence) {
      state.sequence = sequence
    },
    setHeadquarters (state, headquarters) {
      state.headquarters = headquarters.code
      state.terminal = headquarters.terminal
    },
    setPaymentType (state, paymentType) {
      state.paymentType = paymentType
      // console.log(state.paymentType);
    },
    setReceiverId (state, receiverId) {
      state.receiverId = receiverId || null
      // console.log(state.paymentType);
    },
    setState (state, newState) {
      state = { ...state, ...newState }
      // console.log(state.paymentType);
    },
    setProforma (state, proforma) {
      state.proforma = proforma
      // console.log(state.paymentType);
    },
    setDelivery (state, delivery) {
      state.delivery = delivery
      // console.log(state.paymentType);
    },
    addOtherCharge (state, payload) {
      state.otherCharges.push(payload)
    }
  },
  actions: {
    setVoucherType ({ commit }, payload) {
      commit('setVoucherType', payload)
    },
    setPaymentType ({ commit }, payload) {
      commit('setPaymentType', payload)
    },
    newItem ({ commit, dispatch, getters, state, rootGetters }, item) {
      const newItemInvoice = {
        code: item.cabys ? item.cabys : undefined,
        commercialCode: item.commercialCode,
        quantity: tools.round10(item.quantity, -5),
        description: item.description,
        unit: item.unit,
        discountRate: item.discountRate || 0,
        // unitPrice: item.unitPrice
        price: item.price
      }
      if (item.discount) { newItemInvoice.discount = item.discount }
      if (item.taxes) { newItemInvoice.taxes = item.taxes }
      commit('newItem', newItemInvoice)
      const product = {
        cabys: item.cabys,
        company: getters.getIssuer?.idDocument?.number,
        commercialCode: item.commercialCode,
        unit: item.unit,
        description: item.description,
        inventoryable: false,
        prices: [
          {
            priceList: 'general',
            currency: state.currency,
            price: item.price.price
          }
        ],
        taxes: item.taxes ? item.taxes : []
      }
      const productIsNew = !rootGetters['products/getProducts'].find(({ commercialCode }) => commercialCode === item.commercialCode)
      if (state.voucherType !== '08' && state.voucherType !== '12' && productIsNew) {
        dispatch('products/insertProduct', product, { root: true })
          .catch(error => {
            console.error(error)
          })
      }
    },
    deleteReceiver ({ commit }) {
      commit('deleteReceiver')
    },
    updateQuantity ({ commit, state, dispatch }, payload) {
      const item = state.items[payload.index].commercialCode
      dispatch('inventories/checkStock', { code: item, quantity: payload.quantity }, { root: true })
        .then(check => {
          if (check) {
            commit('updateQuantity', payload)
          } else {
            const errMsj = 'No hay suficientes productos codigo ' + item + ' en bodega.'
            dispatch('message/showError', errMsj, { root: true })
          }
        })
    },
    deleteItem ({ commit }, item) {
      commit('deleteItem', item)
    },
    async cleanInvoice ({ commit, state, rootGetters, dispatch }) {
      // const type = (await dispatch['company/getElectronicVoucher']) ? '01' : '10'
      commit('cleanInvoice')
    },
    setReceiver ({ commit, state }, receiver) {
      commit('setReceiver', receiver)
    },
    issueVoucher ({ state, commit, dispatch, getters, rootGetters }, { pos, receipts }) {
      return new Promise((resolve, reject) => {
        commit('setIssuer', getters.getIssuer)
        commit('setSummary', getters.summary)
        commit('setActivity', rootGetters['company/getCompany'].activities[0]?.code)
        commit('setSequence', getters.getSequence)
        commit('setHeadquarters', rootGetters['company/getBranch'])
        const payload = {
          order: state.order,
          seller: state.seller || rootGetters['user/getUser']?.name,
          proforma: state.proforma,
          voucher: { ...state, items: getters.getItems },
          env: rootGetters['company/getCompany'].env,
          user: rootGetters['user/getUser'].uid,
          cashDaily: rootGetters['cashDaily/getcashDaily'] ? rootGetters['cashDaily/getcashDaily'].uid : null,
          applicant: state.applicant || null
        }
        if (!state.receiver && (state.voucherType === '01' || state.voucherType === '12')) {
          reject(new Error('Falta agragar receptor'))
          return
        }
        if (state.voucherType === '12') {
          payload.voucher.receiver = {
            name: rootGetters['company/getCompany'].name,
            identificationDocument: {
              documentType: rootGetters['company/getCompany'].type,
              number: rootGetters['company/getCompany'].identification
            },
            phone: rootGetters['company/getCompany'].phone,
            email: rootGetters['company/getCompany'].email
          }
        }
        // console.log(JSON.stringify(payload));
        const summary = getters.summary
        if (pos) {
          printer.printVoucher(Object.assign({
            sequenceV: getters.genSequence,
            key: getters.key,
            summary
          }, payload.voucher))
            .catch(error => {
              console.error(error)
            })
        }
        redaApp.submitVoucher(payload)
          .then((res) => {
            dispatch('inventories/inventarySale', res, { root: true })
              .catch((err) => console.error(err))
            return res
          })
          .then(({ data, receiver }) => {
            if (receipts && receipts.length > 0) {
              const stack = []
              for (let i = 0; i < receipts.length; i++) {
                const receipt = receipts[i]
                const newPayment = {
                  id: null,
                  company: rootGetters['company/getCompany'].identification,
                  date: Number(moment().format('x')),
                  voucher: data.key,
                  amount: Number(receipt.amount),
                  paymentType: receipt.paymentType,
                  receiptsFrom: '01',
                  currency: receipt.currency || 'CRC',
                  exchangeRate: receipt.exchangeRate || state.exchangeRate || 1,
                  others: receipt.others,
                  receiver: receiver || null,
                  user: rootGetters['user/getUser'].uid,
                  cashDaily: rootGetters['cashDaily/getcashDaily'] ? rootGetters['cashDaily/getcashDaily'].uid : null
                }
                stack.push(dispatch('accountReceivable/insertReceipt', newPayment, { root: true }))
              }
              return Promise.all(stack)
            }
          })
          .then(() => {
            dispatch('cleanInvoice')
            resolve()
          })
          .catch(error => {
            console.error(error)
            reject(error)
            dispatch('message/showError', error, { root: true })
          })
      })
    },
    submitOrder ({ state, commit, dispatch, getters, rootGetters }) {
      return new Promise((resolve, reject) => {
        const now = new Date().getTime()
        const newOrder = {
          customer: state.receiverId || null,
          seller: state.seller || rootGetters['user/getUser']?.name,
          created: now,
          createdBy: rootGetters['user/getUser'].uid,
          items: getters.getItems.map(({ commercialCode, quantity, unitPrice, description }) => ({
            product: commercialCode,
            quantity: Number(quantity),
            customization: null,
            others: null,
            unitPrice,
            description
          })),
          cancelled: false,
          vouchers: [],
          others: null,
          movs: [
            {
              date: now,
              user: rootGetters['user/getUser'].uid,
              value: 'create'
            }
          ],
          task: null,
          applicant: state.applicant || null
        }
        redaApp.submitRedaApp('create-order', newOrder)
          .then(() => {
            dispatch('cleanInvoice')
            resolve()
          })
          .catch(error => {
            console.error(error)
            reject(error)
            dispatch('message/showError', error, { root: true })
          })
      })
    },
    setVoucher ({ state, commit, dispatch, getters, rootGetters }, voucherKey) {
      const voucher = rootGetters['vouchers/getVouchers'].find(({ key }) => key === voucherKey)
      const { data } = voucher
      const reference = {
        documentType: data.documentType,
        number: data.key,
        date: data.date,
        code: '02',
        reason: 'Corrige errores'
      }
      if (data.documentType !== '11') commit('setReferences', [reference])
      const newReceiver = data.receiver ? {
        name: data.receiver.name,
        identificationDocument: {
          documentType: data.receiver.type,
          number: data.receiver.identification
        },
        phone: data.receiver.phone,
        email: data.receiver.email
      } : null
      commit('setReceiverId', voucher.receiver)
      commit('setReceiver', newReceiver)
      const newState = {
        receiverId: voucher.receiver || null,
        creditTerm: data.creditTerm || null,
        currency: data.summary.currency || 'CRC',
        exchangeRate: data.summary.exchangeRate || 1,
        order: voucher.order || null,
        others: data.others || null,
        seller: voucher.seller || null
      }
      state.items = data.items.map((item) => {
        const newItem = {}
        /* if (!this.checkStock({ code: this.tmp.item.code, quantity: round10(this.tmp.item.quantity, -5) })) {
          const errMsj = 'No hay suficientes productos codigo ' + this.tmp.item.code + ' en bodega.'
          this.showError(errMsj)
          return
        } /**/

        newItem.cabys = item.code
        newItem.commercialCode = item.commercialCode
        newItem.quantity = tools.round10(item.quantity, -5)
        newItem.unit = item.unit
        newItem.description = item.description
        newItem.price = { price: Number(item.unit_price || item.unitPrice) }
        newItem.discountRate = 0
        // unitPrice: Number(item.item.unitPrice)
        // total: this.itemSubtotal,
        // subtotal: this.itemSubtotal,
        // taxable_base: this.itemSubtotal,
        // tax_net: this.itemTaxNet,
        // netTotal: this.itemNetTotal,

        if (item.taxes) {
          const newTaxes = item.taxes.map((tax) => ({
            code: tax.code,
            rateCode: tax.rate_code || tax.rateCode,
            rate: tax.rate
          }))
          newItem.taxes = newTaxes
        }

        return newItem
      })
      if (data.documentType === '11') commit('setProforma', data.key)
      commit('setState', newState)
    },
    setOrder ({ state, commit, dispatch, getters, rootGetters }, order) {
      const newReceiver = order.customer ? {
        name: order.customer.name,
        identificationDocument: {
          documentType: order.customer.type,
          number: order.customer.identification
        },
        phone: order.customer.phone,
        email: order.customer.email
      } : null
      commit('setReceiverId', order.customer?.uid || null)
      commit('setReceiver', newReceiver)
      const newState = {
        receiverId: order.customer?.uid || null,
        creditTerm: null,
        currency: 'CRC',
        exchangeRate: 1,
        order: order.uid || null,
        others: order.others || null,
        seller: order.seller || null
      }
      state.items = order.items.map((item) => {
        const newItem = {}
        /* if (!this.checkStock({ code: this.tmp.item.code, quantity: round10(this.tmp.item.quantity, -5) })) {
          const errMsj = 'No hay suficientes productos codigo ' + this.tmp.item.code + ' en bodega.'
          this.showError(errMsj)
          return
        } /**/

        newItem.cabys = item.product.cabys
        newItem.commercialCode = item.commercialCode
        newItem.quantity = tools.round10(item.quantity, -5)
        newItem.unit = item.product.unit
        newItem.description = item.description || item.product.description
        const price = item.product.prices?.find(({ priceList }) => priceList === order.customer?.priceList) || item.product.prices?.find(({ priceList }) => priceList === 'general')
        newItem.price = item.unitPrice ? { price: Number(item.unitPrice) } : price
        newItem.discountRate = 0
        // unitPrice: Number(item.item.unitPrice)
        // total: this.itemSubtotal,
        // subtotal: this.itemSubtotal,
        // taxable_base: this.itemSubtotal,
        // tax_net: this.itemTaxNet,
        // netTotal: this.itemNetTotal,

        if (item.product.taxes) {
          const newTaxes = item.product.taxes.map((tax) => ({
            code: tax.code,
            rateCode: tax.rate_code || tax.rateCode,
            rate: tax.rate
          }))
          newItem.taxes = newTaxes
        }

        return newItem
      })
      commit('setState', newState)
    }
  },
  getters: {
    getOrder (state, getters, rootState, rootGetters) {
      return state.order
    },
    getPaymentType (state, getters, rootState, rootGetters) {
      return state.paymentType
    },
    getItems (state, getters, rootState, rootGetters) {
      const items = []
      state.items.forEach(item => {
        const unitPrice = tools.round10(item.price.price, -5)
        // const unitPrice = item.price.currency === state.currency
        //   ? item.price.price
        //   : state.currency === 'CRC'
        //     ? tools.round10(item.price.price * state.exchangeRate, -5)
        //     : tools.round10(item.price.price / state.exchangeRate, -5)
        item.total = tools.round10(item.quantity * unitPrice, -5)
        item.discount = item.discountRate ? tools.round10((item.discountRate / 100) * item.total, -5) : 0
        item.subtotal = item.discount && item.discount > 0 ? tools.round10(item.total - item.discount, -5) : item.total
        item.taxableBase = item.subtotal
        let taxNet = 0
        if (item.taxes) {
          for (var i = item.taxes.length - 1; i >= 0; i--) {
            item.taxes[i].taxFactor = item.taxes[i].rate / 100
            item.taxes[i].total = item.taxableBase * item.taxes[i].taxFactor
            taxNet += item.taxes[i].total
            if (state.receiver?.allowance /* && state.receiver?.allowance.expirationDate < new Date().getTime() */) {
              const allowance = {
                docType: state.receiver?.allowance.docType,
                docNumber: state.receiver?.allowance.docNumber,
                institution: state.receiver?.allowance.institution,
                date: state.receiver?.allowance.date,
                percentage: state.receiver?.allowance.percentage
              }
              if (state.receiver.allowance.cabys?.includes(item.code) || !state.receiver.allowance.ownCabys) {
                item.taxes[i].allowance = allowance
              }
              if (item.taxes[i].allowance) {
                taxNet = taxNet - (item.taxableBase * (Number(allowance.percentage) / 100))
              }
            }
          }
        }
        item.taxNet = tools.round10(taxNet, -5)
        item.netTotal = tools.round10(item.subtotal + item.taxNet, -5)
        items.push({ ...item, unitPrice })
      })
      return items
    },
    getIssuer (state, getters, rootState, rootGetters) {
      if (state.voucherType === '12') {
        return state.receiver
      }
      const company = rootGetters['company/getCompany']
      return {
        name: company.name,
        tradename: company.tradename,
        idDocument: {
          type: company.type,
          number: company.identification
        },
        location: company.branches[0].location,
        phone: company.phone,
        email: company.email
      }
    },
    getSequence (state, getters, rootState, rootGetters) {
      if (state.voucherType !== '08') {
        const voucherType = state.voucherType === '12' ? '10' : state.voucherType
        const vouchers = rootGetters['vouchers/getVouchers'].filter(
          (voucher) => voucher.data.documentType === voucherType &&
            voucher.branch.code === rootGetters['company/getBranch'].code &&
            Number(voucher.key.slice(24, 29)) === rootGetters['company/getBranch'].terminal
        )
        return vouchers.length + 1
      } else {
        const vouchers = rootGetters['vouchers/vouchersReceiver'].filter(
          (voucher) => voucher.data.documentType === state.voucherType &&
          voucher.branch.code === rootGetters['company/getBranch'].code &&
          voucher.key.slice(24, 29) === rootGetters['company/getBranch'].terminal
        )
        return vouchers.length + 1
      }
    },
    servicesTaxableTotal (state, getters) {
      let servicesTaxableTotal = 0
      for (var i = getters.getItems.length - 1; i >= 0; i--) {
        if (getters.getItems[i].unit === 'Sp') {
          servicesTaxableTotal += (getters.getItems[i].taxes ? tools.round10(getters.getItems[i].subtotal, -5) : 0)
        }
      }
      return tools.round10(servicesTaxableTotal, -5)
    },
    servicesExentTotal (state, getters) {
      let servicesExentTotal = 0
      for (var i = getters.getItems.length - 1; i >= 0; i--) {
        if (getters.getItems[i].unit === 'Sp') {
          servicesExentTotal += (getters.getItems[i].taxes ? 0 : tools.round10(getters.getItems[i].subtotal, -5))
        }
      }
      return tools.round10(servicesExentTotal, -5)
    },
    goodsTaxableTotal (state, getters) {
      let goodsTaxableTotal = 0
      for (var i = getters.getItems.length - 1; i >= 0; i--) {
        if (getters.getItems[i].unit !== 'Sp') {
          goodsTaxableTotal += (getters.getItems[i].taxes ? tools.round10(getters.getItems[i].subtotal, -5) : 0)
        }
      }
      return tools.round10(goodsTaxableTotal, -5)
    },
    goodsExentTotal (state, getters) {
      let goodsExentTotal = 0
      for (var i = getters.getItems.length - 1; i >= 0; i--) {
        if (getters.getItems[i].unit !== 'Sp') {
          goodsExentTotal += (getters.getItems[i].taxes ? 0 : tools.round10(getters.getItems[i].subtotal, -5))
        }
      }
      return tools.round10(goodsExentTotal, -5)
    },
    otherChargesTotal (state, getters) {
      let otherChargesTotal = 0
      for (var i = state.otherCharges.length - 1; i >= 0; i--) {
        otherChargesTotal += tools.round10(state.otherCharges[i].amount, -5)
      }
      return tools.round10(otherChargesTotal, -5)
    },
    taxableTotal (state, getters) {
      return tools.round10(getters.servicesTaxableTotal + getters.goodsTaxableTotal, -5)
    },
    exentTotal (state, getters) {
      return tools.round10(getters.servicesExentTotal + getters.goodsExentTotal, -5)
    },
    subtotal (state, getters) {
      return tools.round10(getters.taxableTotal + getters.exentTotal, -5)
    },
    discountTotal (state, getters) {
      let discountTotal = 0
      for (var i = getters.getItems.length - 1; i >= 0; i--) {
        discountTotal += getters.getItems[i].discount
      }
      return tools.round10(discountTotal, -5)
    },
    taxTotal (state, getters) {
      let taxTotal = 0
      for (var i = getters.getItems.length - 1; i >= 0; i--) {
        taxTotal += getters.getItems[i].taxNet
      }
      return tools.round10(taxTotal, -5)
    },
    netTotal (state, getters) {
      return tools.round10(getters.taxTotal + getters.subtotal + getters.otherChargesTotal, -5)
    },
    summary (state, getters) {
      return {
        servicesTaxableTotal: getters.servicesTaxableTotal,
        servicesExentTotal: getters.servicesExentTotal,
        servicesExoneTotal: 0,
        goodsTaxableTotal: getters.goodsTaxableTotal,
        goodsExentTotal: getters.goodsExentTotal,
        goodsExoneTotal: 0,
        taxableTotal: getters.taxableTotal,
        exentTotal: getters.exentTotal,
        exoneTotal: 0,
        subtotal: getters.subtotal,
        discountTotal: getters.discountTotal,
        grossTotal: getters.subtotal,
        taxTotal: getters.taxTotal,
        vatReturned: 0,
        otherChargesTotal: getters.otherChargesTotal,
        netTotal: getters.netTotal
      }
    },
    genSequence (state) {
      const headquarters = tools.zfill(state.headquarters, 3)
      const terminal = tools.zfill(state.terminal, 5)
      const type = tools.zfill(state.voucherType, 2)
      const number = tools.zfill(state.sequence, 10)
      return headquarters + terminal + type + number
    },
    getVoucherType (state) {
      return state.voucherType
    },
    key (state, getters) {
      const country = '506'
      const day = tools.zfill(moment().date(), 2)
      const month = tools.zfill(moment().month() + 1, 2)
      const year = tools.zfill(moment().year() - 2000, 2)
      const idNumber = tools.zfill(state.issuer.idDocument.number, 12)
      const type = tools.zfill(state.documentSituation, 1)
      const securityCode = tools.zfill(11111111, 8)
      return country + day + month + year + idNumber + getters.genSequence + type + securityCode
    }
  }
}

export default invoice
