<template>
  <v-data-table
    :headers="headers"
    :items="items"
    sort-by="receiver"
    :search="search"
    class="elevation-1"
    dense
  >
    <template v-slot:top>
      <v-toolbar flat color="white">
        <v-toolbar-title>Total ventas por cliente</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          label="Buscar"
          append-icon="mdi-magnify"
          class="mt-3 py-2 mr-2"
        ></v-text-field>
        <v-btn @click="downloadExcel"><v-icon>mdi-file-excel</v-icon></v-btn>
      </v-toolbar>
    </template>
    <template v-slot:item.customerName="{ item }">
      <RouterLink :to="'/customer/'+item.customerId" v-if="item.customerId !== '000000000'">{{ item.customerName }}</RouterLink>
      <div v-else>{{ item.customerName }}</div>
    </template>
    <template v-slot:item.cabys="{ item }">
      {{ item.code && cabys[item.code].description }}
    </template>
    <template v-slot:item.subtotal="{ item }">
      {{ item.subtotal | currencyFormat }}
    </template>
    <template v-slot:item.tax_net="{ item }">
      {{ item.tax_net | currencyFormat }}
    </template>
    <template v-slot:item.action="{ item }">
      <div class="d-flex flex-row">
        <v-btn
          icon
          class="d-flex justify-center"
          :to="'/voucher/'+item.key"
        >
          <v-icon
            small
          >
            mdi-information
          </v-icon>
        </v-btn>
        <v-btn
          class="d-flex justify-center"
          icon
        >
          <v-icon
            small
            @click="newReceipt(item)"
          >
            mdi-cash-plus
          </v-icon>
      </v-btn>
      </div>
    </template>
    <template
      v-slot:body.append
    >
      <tr>
        <td class="title" colspan="2">
          Total ventas
        </td>
        <td>
        </td>
        <td>
        </td>
        <td class="title" style="text-align: end;">
          {{totalSales | currencyFormat}}
        </td>
      </tr>
    </template>
  </v-data-table>
</template>
<script>
import { mapActions } from 'vuex'
import * as cabys from '../assets/cabysIndexed.json'
import tools from '../api/tools'
// import axios from 'axios'

export default {
  name: 'SalesByCustomer',
  components: {
  },
  props: {
    items: {
      type: Array,
      default: () => ([])
    }
  },
  data: () => ({
    voucher: null,
    search: null,
    amountOutstanding: null,
    loading: false,
    cabys: cabys.default,
    headers: [
      {
        text: 'Cédula',
        align: 'left',
        value: 'customer.identification'
      },
      { text: 'Nombre', value: 'customerName' },
      { text: 'Cantidad vendida', value: 'quantity', align: 'end' },
      { text: 'IVA', value: 'tax_net', align: 'end' },
      { text: 'Total', value: 'subtotal', align: 'end' }
    ],
    headersExcel: [
      {
        text: 'Cédula',
        value: 'customer.identification'
      },
      { text: 'Nombre', value: 'customerName' },
      { text: 'Cantidad vendida', value: 'quantity', type: 'number' },
      { text: 'IVA', value: 'tax_net', type: 'number' },
      { text: 'Total', value: 'subtotal', type: 'number' }
    ]
  }),

  computed: {
    totalSales () {
      let total = 0
      // eslint-disable-next-line camelcase
      this.items.forEach(({ subtotal }) => {
        // eslint-disable-next-line camelcase
        total += subtotal
      })
      return total
    }
  },

  watch: {
  },

  /* created () {
      this.initialize()
    }, */

  methods: {
    /* initialize () {
        this.items = this.getCompany.customers
      }, */
    downloadExcel () {
      tools.table2excel(this.items, this.headersExcel, 'ventas_cliente')
    },
    ...mapActions('customers', ['insertCustomer', 'deleteCustomer'])
  }
}
</script>
<style type="text/css">

</style>
