var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-0",attrs:{"items":_vm.items,"hide-default-footer":"","headers":_vm.itemHeaders,"dense":""},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_vm._v(" "+_vm._s(_vm.order.num)+"-"+_vm._s(index + 1)+" ")]}},{key:"item.estimatedDeliveryDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateFormat")(item.po.end,true))+" ")]}},{key:"item.checked",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(item.po)?[(item.status === '02')?_c('v-menu',{attrs:{"bottom":"","right":"","transition":"scale-transition","origin":"top left"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-chip',_vm._g({staticClass:"ma-2",attrs:{"close":"","color":_vm.statusObj[item.status].color,"close-icon":"mdi-chevron-down","small":""}},on),[_c('v-avatar',{attrs:{"left":""}},[_c('v-icon',[_vm._v("mdi-checkbox-marked-circle")])],1),_vm._v(" "+_vm._s(_vm.statusObj[item.status].text)+" ")],1)]}}],null,true)},[_c('v-card',[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{on:{"click":function($event){return _vm.checkItem(index)}}},[_c('v-list-item-action',[_c('v-icon',{attrs:{"color":"success"}},[_vm._v("mdi-check-circle")])],1),_c('v-list-item-title',[_vm._v("Aprobar")])],1),_c('v-list-item',{on:{"click":function () {}}},[_c('v-list-item-action',[_c('v-icon',{attrs:{"color":"warning"}},[_vm._v("mdi-alert-circle")])],1),_c('v-list-item-title',[_vm._v("Se requiere corregir")])],1)],1)],1)],1):_c('v-chip',{staticClass:"ma-2",attrs:{"color":_vm.statusObj[item.status].color,"small":""}},[_c('v-avatar',{attrs:{"left":""}},[_c('v-icon',[_vm._v("mdi-checkbox-marked-circle")])],1),_vm._v(" "+_vm._s(_vm.statusObj[item.status].text)+" ")],1)]:_vm._e()]}},{key:"item.checkedAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateFormat")(item.checkedAt,true))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"display":"flex","flex-direction":"row","align-items":"center","gap":".5rem"}},[_c('dialog-comments',{attrs:{"item":item.po,"icon":"comments"}}),(false)?_c('p-o-dialog',{attrs:{"item":item.po,"icon":"display"}}):_vm._e()],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }