<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          text
          elevation="0"
        >
        {{ label }}
        <v-icon
          v-if="icon"
          right
        >{{ icon }}</v-icon>
        </v-btn>
      </template>
      <v-card>
        <Pos
          :voucher="voucher"
          :order="order"
          :issuer="true"
          :moduleName="moduleName"
          :documentType="documentType"
        ></Pos>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
// import { mapGetters } from 'vuex'
import Pos from '@/components/Pos.vue'

export default {
  name: 'DialogIssueVoucher',
  props: [
    'voucher',
    'order',
    'moduleName',
    'documentType',
    'label',
    'icon'
  ],
  components: {
    Pos
  },
  data () {
    return {
      dialog: false
    }
  },
  methods: {
  },
  computed: {
  },
  mounted () {
  }
}
</script>
