<template>
  <v-container>
    <Period></Period>
    <v-tabs v-model="tab">
      <v-tab>Por producto</v-tab>
      <v-tab>Por cliente</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <v-container fluid>
          <SalesByProduct :items="getSalesItems" />
        </v-container>
      </v-tab-item>
      <v-tab-item>
        <v-container fluid>
          <SalesByCustomer :items="getSalesCustomers" />
        </v-container>
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
/* eslint no-unused-vars: ["error", { "args": "none" }] */
import { mapMutations, mapActions, mapGetters } from 'vuex'
// import { salesVoucherTypes } from '@/assets/codesApp'
import SalesByProduct from '../components/SalesByProduct.vue'
import Period from '../components/Period'
import SalesByCustomer from '../components/SalesByCustomer.vue'
import firebaseApi from '../api/firebaseApi'

export default {
  name: 'SalesReporting',

  data: () => ({
    tab: 0,
    items: [],
    listener: null,
    loading: false
  }),

  components: {
    SalesByProduct,
    SalesByCustomer,
    Period
  },

  computed: {
    getItems () {
      return this.items.filter(({ date }) => (this.getFrom < date && date < this.getTo))
    },
    getSalesItems () {
      const salesItems = this.getItems.reduce((acc, curr) => {
        if (acc[curr.commercialCode]) {
          if (acc[curr.commercialCode].type === '03') {
            acc[curr.commercialCode].quantity -= Number(curr.quantity)
            acc[curr.commercialCode].tax_net -= Number(curr.tax_net)
            acc[curr.commercialCode].subtotal -= Number(curr.subtotal)
            return acc
          }
          acc[curr.commercialCode].quantity += Number(curr.quantity)
          acc[curr.commercialCode].tax_net += Number(curr.tax_net)
          acc[curr.commercialCode].subtotal += Number(curr.subtotal)
          return acc
        }
        acc[curr.commercialCode] = {
          ...curr,
          quantity: Number(curr.quantity),
          tax_net: Number(curr.tax_net),
          subtotal: Number(curr.subtotal)
        }
        return acc
      }, {})
      return Object.values(salesItems)
    },
    getSalesCustomers () {
      const salesItems = this.getItems.reduce((acc, curr) => {
        if (acc[curr.customerId]) {
          if (acc[curr.customerId].type === '03') {
            acc[curr.customerId].quantity -= Number(curr.quantity)
            acc[curr.customerId].tax_net -= Number(curr.tax_net)
            acc[curr.customerId].subtotal -= Number(curr.subtotal)
            return acc
          }
          acc[curr.customerId].quantity += Number(curr.quantity)
          acc[curr.customerId].tax_net += Number(curr.tax_net)
          acc[curr.customerId].subtotal += Number(curr.subtotal)
          return acc
        }
        acc[curr.customerId] = {
          ...curr,
          quantity: Number(curr.quantity),
          tax_net: Number(curr.tax_net),
          subtotal: Number(curr.subtotal)
        }
        return acc
      }, {})
      return Object.values(salesItems)
    },
    ...mapGetters('company', ['getCompany']),
    ...mapGetters(['getFrom', 'getTo'])
  },

  mounted () {
    this.reset()
  },

  beforeDestroy: function () {
    if (this.listener) this.listener()
  },

  methods: {
    async reset () {
      if (this.listener) this.listener()
      this.listener = firebaseApi.db.collection('vouchers')
        .where('issuer', '==', this.getCompany.identification)
        .onSnapshot(({ docs }) => {
          this.items = []
          for (let i = 0; i < docs.length; i++) {
            const doc = docs[i]
            const voucher = { ...doc.data(), uid: doc.id }
            const salesVoucherTypes = [
              '11',
              '08'
            ]
            if (salesVoucherTypes.includes(voucher.data.documentType)) continue
            const customer = voucher.data.receiver || null
            const customerId = voucher.receiverId || voucher.receiver || '000000000'
            for (let j = 0; j < voucher.data.items.length; j++) {
              const item = voucher.data.items[j]
              this.items.push({
                ...item,
                type: voucher.data.documentType,
                date: voucher.date,
                customer: customerId === '000000000' ? null : customer,
                customerName: customerId === '000000000' ? 'Sin nombre' : customer.name,
                customerId
              })
            }
          }
          this.loading = false
        })
    },
    ...mapMutations([]),
    ...mapActions([])
  }
}
</script>
