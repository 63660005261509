<template>
  <v-container>
    <h2>Ordenes de trabajo</h2>
    <approval-orders-table :loading="loading" :tasks="tasks" @updatePO="updateTask($event)" :orders="getOrders"/>
  </v-container>
</template>

<script>
import firebaseApi from '../api/firebaseApi'
import redaApp from '../api/redaApp'
import { mapGetters } from 'vuex'
import ApprovalOrdersTable from '@/components/ApprovalOrdersTable'
import moment from 'moment'

export default {
  name: 'ApprovalOrders',
  data: () => ({
    orders: [],
    customers: {},
    products: {},
    tasks: [],
    loading: true,
    listenerOrders: null,
    listener: null,
    tasksReady: false,
    childs: {},
    tasksIndexed: {}
  }),
  components: {
    ApprovalOrdersTable
  },
  mounted: async function () {
    this.customers = await redaApp.getIndexedItems('customers')
    this.products = await redaApp.getIndexedItems('products')
    this.reset()
  },
  beforeDestroy: function () {
    this.listenerOrders()
    console.log('beforeDestroy')
  },
  methods: {
    async updateTask (orders) {
      this.loading = true
      for (let k = 0; k < orders.length; k++) {
        const order = orders[k]
        const i = this.orders.findIndex(({ uid }) => uid === order)
        if (i < 0) continue
        const task = await redaApp.getTask(this.orders[i].task.uid)
        this.orders[i].task = task
      }
      this.loading = false
    },
    async reset () {
      if (this.listenerOrders) this.listenerOrders()
      if (this.listener) this.listener()
      this.listener = firebaseApi.db.collection('tasks')
        .where('company', '==', this.getCompany.identification)
        .onSnapshot(({ docs }) => {
          this.tasks = []
          this.tasksReady = false
          for (let i = 0; i < docs.length; i++) {
            const doc = docs[i]
            const task = { ...doc.data(), uid: doc.id }
            if (task.product || task.parent || task.orders?.length > 0) {
              this.tasksIndexed[task.uid] = task
              if (task.parent) {
                if (!this.childs[task.parent]) this.childs[task.parent] = []
                this.childs[task.parent].push(task)
              }
            }
            this.tasks.push(task)
          }
          this.tasksReady = true
          this.loading = false
        })
      this.listenerOrders = firebaseApi.db.collection('orders')
        .where('company', '==', this.getCompany.identification)
        .onSnapshot((snapshot) => {
          this.loading = true
          const docs = snapshot.docChanges()
          for (let i = 0; i < docs.length; i++) {
            const { doc, type } = docs[i]
            const uid = doc.id
            if (type === 'removed') {
              const j = this.orders.findIndex((item) => item.uid === uid)
              if (j !== -1) {
                this.orders.splice(j, 1)
              }
            } else if (type === 'modified') {
              const j = this.orders.findIndex((item) => item.uid === uid)
              const order = doc.data()
              order.checked = order.items.every(item => item.check)
              order.vouchers = order.vouchers?.map((key) => (this.getVouchersIndexed[key])) || []
              order.billing = order.vouchers.length > 0
              order.collected = order.vouchers.every(item => item.collected)
              if (j !== -1) {
                this.$set(this.orders, j, { ...order, uid })
              }
            } else {
              const order = doc.data()
              order.checked = order.items.every(item => item.check)
              order.vouchers = order.vouchers?.map((key) => (this.getVouchersIndexed[key])) || []
              order.billing = order.vouchers.length > 0
              order.collected = order.vouchers.every(item => item.collected)
              this.orders.push({ ...order, uid })
            }
          }
          this.loading = false
        })
    }
  },
  computed: {
    productionOrders () {
      const productionOrders = []
      const tasks = this.tasks
      for (let i = 0; i < tasks.length; i++) {
        const task = tasks[i]
        if (task.product) {
          const childs = this.childs[task.uid] || []
          const completedChilds = childs.reduce((acc, curr) => {
            return curr.completed + acc
          }, 0) / (childs.length || 1)
          const start = (task.start || task.created)
          const end = task.duration
            ? Number(moment(start, 'x')
              .add(task.duration.value, task.duration.type)
              .format('x'))
            : null
          const todos = task.todos || []
          const todosLength = todos?.length || 0
          const checked = todos
            .reduce((acc, { value }) => {
              acc += Number(value)
              return acc
            }, 0)
          const completed = task.completed ? task.completed : todosLength > 0
            ? checked / todosLength : (completedChilds || 0)
          const status = completed > 0 ? (completed < 1 ? '04' : '05') : '03'

          const product = this.products[task.product]
          if (!product) console.log(task.product)
          const i = childs
            .findIndex(({ completed }) => Number(completed) === 0)
          task.commercialCode = product?.commercialCode || task.product?.commercialCode
          task.product = product
          task.nextStep = childs[i + 1] || null
          task.currStep = childs[i] || { ...task }
          productionOrders[task.uid] = {
            ...task,
            id: task.uid,
            start,
            end,
            completed: Number(completed.toFixed(2)),
            status: status !== '05' &&
              task.deadline && task.deadline < new Date().getTime()
              ? '01' : status,
            childs
          }
        }
      }
      return productionOrders
    },
    getOrders () {
      return this.orders.map((element, index, orders) => {
        const order = JSON.parse(JSON.stringify(element))
        const items = order.items.map((item) => {
          const po = item.po ? this.productionOrders[item.po] : null
          if (po === undefined) console.log({ ...item }, { ...element })
          const status = item.check ? '04' : item.po && item.po.status === '05' ? '02' : item.po && item.po.status === '07' ? '01' : '03'
          return {
            ...item,
            status,
            product: this.products[item.product],
            po
          }
        })
        if (order.task) {
          const task = this.tasksIndexed[order.task]
          if (!task) console.log({ ...order })
          const childs = this.childs[task.uid]
          const completedChilds = childs.reduce((acc, curr) => {
            return curr.completed + acc
          }, 0) / (childs.length || 1)
          const start = (task.start || task.created)
          const end = task.duration
            ? Number(moment(start, 'x')
              .add(task.duration.value, task.duration.type)
              .format('x'))
            : null
          const todos = task.todos || []
          const todosLength = todos?.length || 0
          const checked = todos
            .reduce((acc, { value }) => {
              acc += Number(value)
              return acc
            }, 0)
          const completed = task.completed ? task.completed : todosLength > 0
            ? checked / todosLength : (completedChilds || 0)
          const status = completed > 0 ? (completed < 1 ? '04' : '05') : '03'
          order.task = {
            ...task,
            id: task.uid,
            start,
            end,
            assignee: this.customers[task.assignee] || null,
            completed: Number(completed.toFixed(2)),
            status: status !== '05' &&
              task.deadline && task.deadline < new Date().getTime()
              ? '01' : status,
            childs
          }
        }
        order.customer = this.customers[order.customer]
        order.items = items
        order.num = index + 1
        return order
      })
    },
    getVouchersIndexed () {
      return this.getVouchers.reduce((acc, cur) => {
        acc[cur.uid] = cur
        return acc
      }, {})
    },
    ...mapGetters('company', [
      'getCompany'
    ]),
    ...mapGetters('vouchers', ['getVouchers'])
  }
}
</script>
