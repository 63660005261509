<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog"
      max-width="400"
    >
      <template v-slot:activator="{ on, attrs }">
        <div
          v-bind="attrs"
          v-on="on"
        >
          <div v-if="!icon" class="info--text">
            Ver detalles
          </div>
          <RedaIcon
            :iconName="icon"
            v-else
          ></RedaIcon>
        </div>
      </template>
      <POCard :item="item" @close="dialog = false" @updated="$emit('updated', $event); dialog = false"></POCard>
    </v-dialog>
  </div>
</template>
<script>
import POCard from '@/components/POCard.vue'
export default {
  name: 'PODialog',
  data () {
    return {
      dialog: false
    }
  },
  props: {
    item: Object,
    icon: String
  },
  components: {
    POCard
  }
}
</script>
